.arrow {
    font-size: 0.8em;
}

.content-wrapper {
    padding-bottom: 100px;
} 

ul {
  list-style-type: disc;  /* traditional bullet */
  margin-left: 20px;      /* indent the list */
}

/* Or for custom bullets: */
ul {
  list-style-type: none;  /* remove default bullets */
}

ul li:before {
  content: "•";          /* custom bullet character */
  margin-right: 8px;     /* space between bullet and text */
}