table.onetris, table.onetris th, table.onetris td {
  border: 1px solid black;
  border-collapse: collapse;
}

table.onetris th, table.onetris td {
  width:20px;
  height:20px;
}

table.onetris {
  margin: 100px;
}