.top-nav {
  background-color: #333;
  padding: 1em;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.nav-logo {
  height: 30px;  /* Adjust this value to match your desired logo size */
  vertical-align: middle;
}

#logo-link {
  padding: 5px 10px;  /* Smaller padding for the logo */
}

.top-nav a {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  margin-right: 10px;
}

.top-nav a:hover {
  background-color: #555;
  border-radius: 4px;
} 